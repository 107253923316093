import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { criticalBloodPressureValue, criticalGlycemiaValue } from "happ-custom-hooks/valid-ranges/valid-ranges";

const LastResultExam = ({origin, result}) => {
    const [exam, setExam] = useState([]);
    const [isCritical, setIsCritical] = useState(null);
    const filterResultsByOrigin = (origin) => {
        if (result.length) {
            const filterExam = result.filter(item => item.exam === origin);
            setExam(filterExam);
            if (origin === 'Toma de presion 1' && filterExam.length) {
                const alerts = criticalBloodPressureValue(filterExam[0].results.sistolica, filterExam[0].results.diastolica);
                if (Object.keys(alerts).length) {
                    setIsCritical(true);
                } else {
                    setIsCritical(null);
                }
            }
            if (origin === 'Hemoglucotest' && filterExam.length) {
                const alerts = criticalGlycemiaValue(filterExam[0].results.glicemia);
                if (Object.keys(alerts).length) {
                    setIsCritical(true);
                } else {
                    setIsCritical(null);
                }
            }
        }
    }
    useEffect(() => {
      filterResultsByOrigin(origin);
    }, [result, origin]);
    
    
    return (
        <>
            {exam.length ? (
                <Col className="content-form" sm={7} md={7} lg={7}>
                    <h4>Ultimo resultado del dia de hoy:</h4><br></br>
                    {
                        origin === 'Toma de presion 1' ? (
                            <>
                                <p>Sistolica: <strong>{exam[0].results.sistolica} mmhg</strong></p>
                                <p>Diastolica: <strong>{exam[0].results.diastolica} mmhg</strong></p>
                                {isCritical !== null ? (
                                    <p>Estado: <strong style={{ color: 'red' }}>Resultado Crítico</strong></p>
                                ) : null}
                            </>
                        ) :
                        origin === 'Hemoglucotest' ? (
                            <>
                                <p>Glicemia capilar : <strong>{exam[0].results.glicemia} mg/dl</strong></p>
                                <p>En ayuno ? : <strong>{exam[0].results.fast ? 'SI' : 'NO'}</strong></p>
                                {isCritical !== null ? (
                                    <p>Estado: <strong style={{ color: 'red' }}>Resultado Crítico</strong></p>
                                ) : null}
                            </>
                        ) :
                        origin === 'Estudio del peso' ? (
                            <>
                                <p>Peso <strong>{exam[0].results.weight} kg</strong></p>
                                <p>Altura <strong>{exam[0].results.height} cm</strong></p>
                                <p>Circunferencia <strong>{exam[0].results.circumference} cm</strong></p> 
                                <p>IMC <strong>{exam[0].results.imc}</strong></p> 
                            </>
                        ) : null
                    }
                </Col>
            ) : null}
        </>
    );
}
 
export default LastResultExam;