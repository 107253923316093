import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Col, Row, Form, Button} from 'react-bootstrap';
import SweetAlert2 from 'react-sweetalert2';
import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';
import { getHealthBenefitByCodeAction, saveExamParamsAction } from 'happ-redux/happ-actions/exams/examsActions';
import LastResultExam from 'happ-components/LastResultExam/LastResultExam';
import { getInputToValidate, criticalBloodPressureValue } from 'happ-custom-hooks/valid-ranges/valid-ranges';
import ErrorMessage from 'happ-components/Alerts/ErrorMessage';
const initState = {
    sistolica: '',
    diastolica: ''
}
const BloodPressureForm = ({stage}) => {
    const [errorsInput, setErrorsInput] = useState(null);
    const [criticalAlerts, setCriticalAlerts] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        state,
        errors,
        handlerChangeNumber,
        handlerSubmit
    } = useValidatorForm(initState, sendResults, 'bloodPressure');
    const {sistolica, diastolica} = state;
    const { patient, currentMedicalStage, operativeId } = useSelector(state => state.profile);
    const { summaryResults } = useSelector(state => state.auth);
    useEffect(() => {
        if (stage === 'blood-pressure') {
            dispatch(getHealthBenefitByCodeAction('toma-presion-1', 'dc-1', navigate));
        }
        if (stage === 'blood-pressure-two') {
            dispatch(getHealthBenefitByCodeAction('toma-presion-2', 'c-1', patient?._id, navigate));
        }
    }, []);
    
    const [examMessage, setExamMessage] = useState({
        show: true,
        text: 'Resultado registrado con exito',
        type: 'success',
        icon: 'success',
    });
    const { healthBenefit, saveParams } = useSelector(state => state.exams);
    const {loginProfile} = useSelector(state => state.auth);
    function sendResults() {
        const errorsInputs = getInputToValidate('bloodPressure', {sistolica, diastolica});
        if (Object.keys(errorsInputs).length) {
            setErrorsInput(errorsInputs);
            return;
        }
        setErrorsInput(null);
        setCriticalAlerts({});
        const alerts = criticalBloodPressureValue(sistolica, diastolica);
        if (Object.keys(alerts).length) {
            setCriticalAlerts(alerts);
        }
        let body = {
            patient: patient._id,
            healthBenefits: healthBenefit._id,
            status: true,
            admin: loginProfile._id,
            operative: operativeId,
            medicalStage: currentMedicalStage._id,
            params: {
                sistolica,
                diastolica
            }
        }
        if(stage === 'blood-pressure') {
            body.origin = 'Happ';
            body.algorithmName = 'hypertension';
            dispatch(saveExamParamsAction(navigate, body, 'dc-1'));
        }
        if (stage === 'blood-pressure-two') {
            body.origin = 'Happ2';
            dispatch(saveExamParamsAction(navigate, body, 'c-1'));            
        }
        state.sistolica = '';
        state.diastolica = '';
    }
    return ( 
        <>
        {saveParams !== null && saveParams ? (
            <SweetAlert2
                {...examMessage}
            />
        ) : null} 
        {saveParams !== null && !saveParams ? (
            <Row className="align-items-center mt-5">
                <p className='success-save'>No se pudo registrar el resultado</p>
            </Row>
        ) : null} 
        <Row className="justify-content-md-center">
            <Col md={7} sm={7} lg={7} className="content-form">
                <Form
                    onSubmit={handlerSubmit}
                >
                    <Row className='justify-content-md-center'>
                        <Col md={4} sm={4} lg={4}>
                            <Form.Label>Sistolica</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="sistolica" 
                                placeholder="Sistolica"
                                onChange={handlerChangeNumber}
                                value={sistolica ? sistolica : ''} 
                            />
                            {errors && errors.sistolica ? (
                                <p>{errors.sistolica}</p>
                            ) : null}
                        </Col>
                        <Col md={4} sm={4} lg={4}>
                            <Form.Label>Diastolica</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="diastolica" 
                                placeholder="Diastolica"
                                onChange={handlerChangeNumber}
                                value={diastolica ? diastolica : ''} 
                            />
                            {errors && errors.diastolica ? (
                                <p>{errors.diastolica}</p>
                            ) : null}
                        </Col>
                    </Row>
                    <Col md={12} sm={12} lg={12} className="mt-3 text-center">
                        <Button
                            type='submit'
                            className='btn btn-primary'
                        >Guardar</Button>
                    </Col>
                </Form>
            </Col>
        </Row>
        {errorsInput && Object.keys(errorsInput).length ? (
            <Row className="justify-content-md-center">
                {Object.keys(errorsInput).map((error) => (
                    <ErrorMessage
                        key={error} 
                        message={errorsInput[error]}
                    />
                ))}
            </Row>
        ) : null}
        {criticalAlerts && Object.keys(criticalAlerts).length ? (
            <Row className="justify-content-md-center">
                {Object.keys(criticalAlerts).map((error) => (
                    <ErrorMessage
                        key={error} 
                        message={criticalAlerts[error]}
                    />
                ))}
            </Row>
        ) : null}
        <Row className="justify-content-md-center">
            {summaryResults && summaryResults.length ? (
                <LastResultExam 
                    origin={'Toma de presion 1'}
                    result={summaryResults}
                />
            ) : null
            }
        </Row>
        </>
    );
}
 
export default BloodPressureForm;