import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Col, Row, Form, Button, ListGroup, Spinner} from 'react-bootstrap';
import InfoPatientNav from 'happ-components/Navbar/InfoPatientNav';
import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';
import { getBoxListAction, assignBoxToPatientAction } from 'happ-redux/happ-actions/box/boxActions';
import { calculateMedicalAgendaByPatientAction, validateMedicalAgendaPatientAction } from 'happ-redux/happ-actions/exams/examsActions';
import { summaryPatientAction } from 'happ-redux/happ-actions/auth/authActions';
import { CheckCircleFill, InfoSquareFill } from 'react-bootstrap-icons';
import ErrorMessage from 'happ-components/Alerts/ErrorMessage';


const initState = {
    box: ''
}
const AssignBox = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        state,
        errors,
        handlerChange,
        handlerSubmit
    } = useValidatorForm(initState, sendResults, 'assign-box');
    const {box} = state;
    const {loginProfile, summarySurvey, summaryResults, errorSummary, continueStatus, examsLabs} = useSelector(state => state.auth);
    const {currentMedicalStage, patient, operativeId, isHypertensive} = useSelector(state => state.profile);
    const {assignBox, assignBoxMsg} = useSelector(state => state.box);
    useEffect(() => {
        dispatch(summaryPatientAction(patient?._id, operativeId, currentMedicalStage, isHypertensive, navigate));
        dispatch(getBoxListAction(loginProfile?._id, currentMedicalStage?._id, navigate));
        const body = {
            patient: patient?._id,
            medicalStage: currentMedicalStage?._id
        }
        dispatch(validateMedicalAgendaPatientAction(body));
    }, [])
    
    const {isMedicalAgenda, medicalAgendaMsg, loadingExams} = useSelector(state => state.exams);
    const {boxes, errorBoxList} = useSelector(state => state.box);
    function sendResults() {
        const body = {
            box,
            operative: operativeId,
            patient: patient._id,
        }
        dispatch(assignBoxToPatientAction(body));
    }
    const calculation = e => {
        e.preventDefault();
        const body = {
            dni: patient?.dni,
            operative: operativeId,
            medicalStage: currentMedicalStage?._id
        }
        dispatch(calculateMedicalAgendaByPatientAction(body));
    }
    return ( 
        <>
        <InfoPatientNav />
        {assignBox != null && assignBox === true ? (
            <Row className="align-items-center mt-5">
                <p className='success-save'>Box asignado con exito</p>
            </Row>
        ) : null}
        {assignBox != null && assignBox === false ? (
            <Row className="align-items-center mt-5">
                <p className='error-save'>{assignBoxMsg}</p>
            </Row>
        ) : null}
        <Row className="justify-content-md-center">
            <Col md={7} sm={7} lg={7} className="content-form">
                {loginProfile?.role === 3 || loginProfile?.role === 8 ? (
                    <>
                        {!errorSummary ? (
                            <Row className='mb-5'>
                                <Col md={7} sm={7} lg={7} className="mt-3">
                                    <p className='mb-2'> <strong>Encuesta:</strong> </p>
                                    {summarySurvey && summarySurvey.results.length ? (
                                        <>
                                            <ListGroup>
                                                {summarySurvey.results.map((item, pos) => (
                                                    <ListGroup.Item key={pos}>
                                                        {item.item} {item.value === false ? <strong className='res-strong'>No</strong> : <strong className='res-yes-strong'>Si</strong>}
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>    
                                        </>
                                    ) : (
                                            <p>{patient?.firstName} no tiene ninguna encuesta rcv registrada</p>
                                        )}
                                </Col>
                                <Col md={5} sm={5} lg={5}>
                                    <p className='mb-2'> <strong>Resultados de hoy:</strong> </p>
                                    {summaryResults && summaryResults.length ? (
                                        <>
                                        <ListGroup>
                                            {summaryResults.map((item, pos) => (
                                                <ListGroup.Item key={pos}>
                                                    {item.exam === "Hemoglucotest" ? (
                                                        <>
                                                        <strong>Hemoglucotest: </strong> <br/>
                                                        Glicemia {item.results.glicemia}  
                                                        <strong
                                                            style={{
                                                                fontSize: '15px',
                                                                color: 'red',
                                                                marginLeft: '5px'
                                                            }}
                                                        >{Number(item.results.glicemia) >= 350 ? 'Valor Crítico' : null} </strong>
                                                        </>  
                                                    ) : null}
                                                    {item.exam === "Estudio del peso" ? (
                                                        <>
                                                        <strong>IMC: </strong> <br/>
                                                        Peso {item.results.weight} cm <br/>
                                                        Altura {item.results.height} cm <br/>
                                                        Circunferencia {item.results.circumference} cm  
                                                        </>  
                                                    ) : null}
                                                    {item.exam === "Toma de presion 1" ? (
                                                        <>
                                                        <strong>Toma de Presión: </strong> <br/>
                                                        Sistolica {item.results.sistolica} 
                                                        <strong
                                                            style={{
                                                                fontSize: '15px',
                                                                color: 'red',
                                                                marginLeft: '5px'
                                                            }}
                                                        >{Number(item.results.sistolica) >= 180 ? 'Valor Crítico' : null}</strong>
                                                        <br/>
                                                        Diastolica {item.results.diastolica}
                                                        <strong
                                                            style={{
                                                                fontSize: '15px',
                                                                color: 'red',
                                                                marginLeft: '5px'
                                                            }}
                                                        >{Number(item.results.diastolica) >= 120 ? 'Valor Crítico' : null}</strong>
                                                        </>  
                                                    ) : null}
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                        </>
                                    ) : (
                                        <p>{patient?.firstName} no tiene ninguna resultado registrado</p>
                                    )}
                                </Col>
                            </Row>
                        ) : null}
                        {loadingExams ? (
                            <div className='text-center mt-5 mb-5'>
                                <Spinner
                                    animation="border"
                                />
                                <p className='mt-2'><strong>Generando exámenes, por favor espero…</strong></p>
                            </div>
                        ) : (
                            !isMedicalAgenda ? (
                                <>
                                {medicalAgendaMsg ? (
                                    <ErrorMessage 
                                        message={medicalAgendaMsg}
                                    />
                                ) : null}
                                <Button
                                    type='submit'
                                    className='btn btn-primary btn-box'
                                    onClick={calculation}
                                >Calcular agenda de {patient?.firstName}</Button>
                                </>
                            ) : (
                                <>
                                    <h4 style={{ position: 'relative' }}> 
                                        Se genero la orden medica con éxito. 
                                        <CheckCircleFill 
                                            className='icon-check-om'
                                        />
                                    </h4>
                                    <h4>
                                        <InfoSquareFill 
                                            className='icon-info-om'
                                        />
                                        Si al paciente no le llegan sus resultados a su teléfono: 
                                        pedir que ingresar a la pagina: app.happlabs.cl
                                    </h4>
                                </>
                            )
                        )}
                    </>
                ) : loginProfile?.role === 2 ? (
                    currentMedicalStage && currentMedicalStage.code === 'confirmatory-diagnostic-complementary-one' ? (
                        <>
                            {!errorBoxList ? (
                                <>
                            {!errorSummary ? (
                                <Row className='mb-5'>
                                    <Col md={5} sm={5} lg={5}>
                                        <p className='mb-2'> <strong>Resultados de hoy:</strong> </p>
                                        {summaryResults && summaryResults.length ? (
                                            <>
                                            <ListGroup>
                                                {summaryResults.map((item, pos) => (
                                                    <ListGroup.Item key={pos}>
                                                        {item.exam === "Toma de presion 2" ? (
                                                        <>
                                                            <strong>Toma de Presión Confirmatoria: </strong> <br/>
                                                            Sistolica {item.results.sistolica} <br/>
                                                            Diastolica {item.results.diastolica} 
                                                        </>  
                                                        ) : null}
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                            </>
                                        ) : (
                                            <p>{patient?.firstName} no tiene ninguna resultado registrado</p>
                                        )}
                                    </Col>
                                    <Col md={7} sm={7} lg={7}>
                                        <p className='mb-2'> <strong>Examenes de laboratorio:</strong> </p>
                                        {examsLabs && examsLabs.length ? (
                                            <>
                                            <ListGroup>
                                                {examsLabs.map((item, pos) => (
                                                    <ListGroup.Item key={pos}>
                                                       {item.healthBenefit.name}
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                            </>
                                        ) : (
                                            <p>{patient?.firstName} no tiene examenes de laboratorio</p>
                                        )}
                                    </Col>
                                </Row>
                            ) : null}
                            {continueStatus !== null && continueStatus === true ? (
                                <Form
                                    onSubmit={handlerSubmit}
                                >
                                    <Row className='justify-content-md-center'>
                                        <Col md={4} sm={4} lg={4}>
                                            <Form.Select 
                                                aria-label="Default select example"
                                                name="box"
                                                value={box ? box : ""}
                                                onChange={handlerChange}
                                            >
                                                <option value="">Seleccione un Box</option>
                                                {boxes && boxes.map(item => (
                                                    <option key={item._id} value={item._id}>{item.name}</option>
                                                ))}
                                            </Form.Select>
                                            {errors && errors.box ? (
                                                <p>{errors.box}</p>
                                            ) : null}                            
                                        </Col>
                                        <Col md={4} sm={4} lg={4}>
                                            <Button
                                                type='submit'
                                                className='btn btn-primary btn-box'
                                            >Asignar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            ) : (<p className='warnign-box'>Para continuar con la siguente etapa debe ingresar el resultado de la segunda toma de presión</p>)}
                                </>
                        ) : null}
                        </>    
                    ) : (
                        <>
                            {!errorBoxList ? (
                                <>
                                    <Form
                                        onSubmit={handlerSubmit}
                                    >
                                        <Row className='justify-content-md-center'>
                                            <Col md={4} sm={4} lg={4}>
                                                <Form.Select 
                                                    aria-label="Default select example"
                                                    name="box"
                                                    value={box ? box : ""}
                                                    onChange={handlerChange}
                                                >
                                                    <option value="">Seleccione un Box</option>
                                                    {boxes && boxes.map(item => (
                                                        <option key={item._id} value={item._id}>{item.name}</option>
                                                    ))}
                                                </Form.Select>
                                                {errors && errors.box ? (
                                                    <p>{errors.box}</p>
                                                ) : null}                            
                                            </Col>
                                            <Col md={4} sm={4} lg={4}>
                                                <Button
                                                    type='submit'
                                                    className='btn btn-primary btn-box'
                                                >Asignar</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </>
                            ) : null}
                        </>
                    )
                ): null}
            </Col>
        </Row>
        </>            
     );
}
 
export default AssignBox;